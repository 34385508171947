import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { User } from "firebase/auth";

export interface UserInfo extends User {
  profileImage?: string;
  nick?: string;
  date_regis?: string;
  book_mark?: number[];
  likes?: number[];
  id?: number;
  role?: string;
  admin?: number;
}
interface UserState {
  userInfo: UserInfo | null;
  getUser: () => UserInfo | null;
  setUser: (user: UserInfo | null) => void;
  updateUser: (data: Partial<UserInfo>) => void;
  clearUser: () => void;
}

export const useUserStore = create<UserState>()(
  devtools(
    (set, get) => ({
      userInfo: null,
      getUser: () => get().userInfo,
      setUser: (user) => set({ userInfo: user }),
      updateUser: (data) =>
        set((state) => ({ userInfo: { ...state.userInfo, ...data } })),
      clearUser: () => set({ userInfo: null }),
    }),
    { name: "userStore" }
  )
);
