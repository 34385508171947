import {
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineDateRange, MdOutlineFavoriteBorder } from "react-icons/md";
import { IoMdLink } from "react-icons/io";
import ModalMenu from "../ModalMenu";
import { useUserStore } from "../../../store/useUserStore";
import { useMarkerStore } from "../../../store/useMarkerStore";
import { PopupContainerStyle } from "./style";
import { BsBookmarkStar, BsBookmarkStarFill } from "react-icons/bs";
import { IconStyle } from "../../../style/common";
import { MarkerData } from "../../../types/marker";
import {
  handleBookmarkToggle,
  useBookmarkCountRealtime,
} from "@/api/marker/bookmark";
import { MdOutlineFavorite } from "react-icons/md";
import { handleLikeToggle, useLikeCountRealtime } from "@/api/marker/like";
import { PhotoDataType } from "@/api/myphoto/type";
import { TbCurrentLocation } from "react-icons/tb";
import useBoolean from "@/hooks/useBoolean";
import UserPhotoList from "@/components/userPhotoList";
import { useState } from "react";

interface MarkerPopupProps {
  data: Partial<PhotoDataType>;
  isOpen: boolean;
  onClose: () => void;
  type?: string;
  moveToPosition?: (data: PhotoDataType) => void;
  onCloseMyPhoto?: () => void;
}

export interface UserData {
  user_id: number;
  nick: string;
  profile_image: string;
}

function MarkerPopup({
  isOpen,
  onClose,
  data,
  type,
  moveToPosition,
  onCloseMyPhoto,
}: MarkerPopupProps) {
  const toast = useToast();
  const { getUser } = useUserStore();
  const userInfo = getUser();
  const setMarker = useMarkerStore((state: any) => state.setMarker);
  const onCloseModal = () => {
    setMarker({});
    onClose();
  };

  //북마크 카운트
  const { data: bookmarkCount } = useBookmarkCountRealtime(
    data.uid,
    data.bookmark_count
  );

  //좋아요 카운트
  const { data: likeCount } = useLikeCountRealtime(data.uid, data.like_count);

  const onMoveToPosition = (data: PhotoDataType) => {
    toast({
      title: "선택환 사진의 위치와 날짜로 변경 되었습니다.",
      status: "success",
      duration: 2500,
      position: "top",
      isClosable: true,
    });
    moveToPosition(data);
    onCloseModal();
    onCloseMyPhoto();
  };

  //유저 사진 리스트 모달
  const isUserPhotoListOpen = useBoolean();
  const [selectUser, setSelectUser] = useState<UserData | null>(null);
  //유저 리스트 이동
  const onUserList = (user: UserData) => {
    setSelectUser(user);
    onCloseModal();
    isUserPhotoListOpen.onTrue();
  };
  if (!userInfo) return null;
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <PopupContainerStyle>
              <div className="img-box">
                <img className="popup-image" src={data.image_url} alt="Image" />
              </div>
              <section className="content-box">
                {/* 반응하기 버튼 */}
                <div className="reaction-box">
                  {/* 좋아요 버튼 */}
                  <IconStyle
                    onClick={() =>
                      handleLikeToggle(Number(data.uid), data as MarkerData)
                    }
                  >
                    {userInfo.likes?.includes(Number(data.uid)) ? (
                      <MdOutlineFavorite />
                    ) : (
                      <MdOutlineFavoriteBorder />
                    )}
                    <span>{likeCount || data.like_count}</span>
                  </IconStyle>
                  {/* 북마크 버튼 */}
                  <IconStyle
                    onClick={() =>
                      handleBookmarkToggle(Number(data.uid), data as MarkerData)
                    }
                  >
                    {userInfo.book_mark?.includes(Number(data.uid)) ? (
                      <BsBookmarkStarFill />
                    ) : (
                      <BsBookmarkStar />
                    )}
                    <span>{bookmarkCount || data.bookmark_count}</span>
                  </IconStyle>
                  {type === "list" && (
                    //리스트에서 현재 위치로 이동 아이콘 보이기
                    <IconStyle style={{ marginLeft: "auto" }}>
                      <TbCurrentLocation
                        onClick={() => onMoveToPosition(data as PhotoDataType)}
                        className="ic-location"
                      />
                    </IconStyle>
                  )}
                </div>
                {type !== "list" && (
                  //리스트가 아닐때 유저 정보 보이기
                  <div className="top-info">
                    <div className="user" onClick={() => onUserList(data)}>
                      <Avatar size="xs" src={data.profile_image} />
                      {data.nick}
                    </div>
                    {userInfo.id === Number(data.user_id) && (
                      <ModalMenu data={data} onCloseModal={onCloseModal} />
                    )}
                  </div>
                )}
                <div className="info-list">
                  <div className="info-item">
                    <span className="info-key">
                      <MdOutlineDateRange />
                    </span>
                    <span className="info-value">{data.date}</span>
                  </div>
                  {data.link && (
                    <div className="info-item">
                      <span className="info-key">
                        <IoMdLink />
                      </span>
                      <span className="info-value link">
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.link}
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              </section>
            </PopupContainerStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* 유저 사진 리스트 */}
      {selectUser && (
        <UserPhotoList
          isOpen={isUserPhotoListOpen.boolean}
          onCloseModal={isUserPhotoListOpen.onFalse}
          selectUser={selectUser}
        />
      )}
    </>
  );
}

export default MarkerPopup;
