import { useMap } from "react-leaflet";
import { format } from "date-fns";
import { useDateStore } from "../store/useDateStore";
import { PhotoDataType } from "@/api/myphoto/type";

export const useCurrentPosition = (onDateSet?: (date: string) => void) => {
  const map = useMap();
  const setDate = useDateStore((state) => state.setDate);

  const moveToPosition = (data: PhotoDataType) => {
    map.setView([data.lat, data.lng], 18);
    const formattedDate = format(new Date(data.date), "yyyy-MM");

    if (onDateSet) {
      onDateSet(formattedDate);
    }
    setDate(formattedDate);
  };

  return { moveToPosition };
};
