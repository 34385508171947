import { useUserStore } from "@/store/useUserStore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "@/firebase";
import { onValue, ref, update } from "firebase/database";
import {
  useApplyUploaderApprove,
  useGetApplyUploaderList,
} from "@/api/auth/applyUploader";
import { UpLoaderListStyle } from "./style";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

const index = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();

  //관리자 인증
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}`);
        onValue(userRef, (data) => {
          if (data.val()) {
            if (data.val().admin > 0) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
              alert("관리자가 아닙니다.");
              navigate("/");
            }
          }
        });
      } else {
        navigate("/login");
      }
    });
  }, []);

  //업로더 신청 목록
  const { data: applyUploaderListData } = useGetApplyUploaderList();

  //업로더 신청 승인
  const { mutate: applyUploaderApprove } = useApplyUploaderApprove();
  const handleApplyUploader = (item: any) => {
    applyUploaderApprove(item.id, {
      onSuccess: () => {
        const dbRef = ref(db, `users/${item.uid}`);
        update(dbRef, {
          role: "uploader",
        });
        toast({
          title: "승인되었습니다.",
          status: "success",
        });
        queryClient.invalidateQueries({ queryKey: ["getApplyUploaderList"] });
      },
      onError: () => {
        toast({
          title: "승인 실패",
          status: "error",
        });
      },
    });
  };

  if (!isAdmin) return <div></div>;
  return (
    <UpLoaderListStyle>
      <div>
        <h1>업로더 신청 목록</h1>
        <ul>
          <li className="header">
            <span className="nick">닉네임</span>
            <span className="introduction">소개</span>
            <span className="sns">SNS</span>
            <span className="state">상태</span>
          </li>
          {applyUploaderListData?.data?.list?.map((item: any) => (
            <li key={item.id}>
              <span className="nick">{item.nick}</span>
              <span className="introduction">{item.introduction}</span>
              <span className="sns">{item.sns}</span>
              <span className="state">
                {item.state === "apply" ? (
                  "승인"
                ) : (
                  <>
                    대기
                    <Button
                      colorScheme="blue"
                      onClick={() => handleApplyUploader(item)}
                    >
                      승인
                    </Button>
                  </>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </UpLoaderListStyle>
  );
};

export default index;
