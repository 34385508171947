import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { format } from "date-fns";

export const useDateStore = create<{
  date: string;
  setDate: (date: string) => void;
  getDate: () => string;
  dateType: string;
  setDateType: (dateType: string) => void;
  getDateType: () => string;
}>()(
  devtools(
    (set, get) => ({
      date: format(new Date(), "yyyy-MM"),
      setDate: (date) => set({ date }),
      getDate: () => get().date,
      dateType: "monthly",
      setDateType: (dateType) => set({ dateType }),
      getDateType: () => get().dateType,
    }),
    { name: "dateStore" }
  )
);
