import { mixFlex } from "@/style/mixIn";
import styled from "styled-components";

export const UpLoaderListStyle = styled.div`
  padding: 2rem;
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  ul {
    .header {
      padding: 0.75rem 0;
      background-color: #f9f9f9;
      border-bottom: 0;
      ${mixFlex("flex-start", "center")};
      span {
        padding: 1rem;
        font-weight: 700;
      }
    }
    li {
      ${mixFlex("flex-start", "center")};
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: 0;
      }
      span {
        padding: 1rem;
        ${mixFlex()};
        gap: 0.5rem;
        &.nick {
          flex: 0.5;
        }
        &.introduction {
          flex: 2;
        }
        &.sns {
          flex: 1;
        }
        &.state {
          flex: 0.5;
        }
      }
    }
  }
`;
