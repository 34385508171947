import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { PhotoListContainerStyle } from "../modal/myPhotoList/style";
import { PhotoListStyle } from "../modal/myPhotoList/style";
import { useGetPhotoList } from "@/api/myphoto/useGetPhoto";
import { useInView } from "react-intersection-observer";
import { PhotoDataType } from "@/api/myphoto/type";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import MarkerPopup, { UserData } from "../modal/MarkerDetail/MarkerPopup";
import useBoolean from "@/hooks/useBoolean";
import { useCurrentPosition } from "@/hooks/useCurrentPosition";
import UserProfileBox from "../profileBox/userProfileBox";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  selectUser: UserData;
};

const UserPhotoList = ({ isOpen, onCloseModal, selectUser }: Props) => {
  const {
    data: userPhotoData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPhotoList(selectUser.user_id, 20);
  const { ref: moreRef, inView: myPhotoInView } = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (myPhotoInView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [myPhotoInView]);

  const [photoList, setPhotoList] = useState<PhotoDataType[]>([]);

  useEffect(() => {
    if (userPhotoData) {
      setPhotoList(userPhotoData.pages.flatMap((page) => page.data.list));
    }
  }, [userPhotoData]);

  //해당 마커 위치로 이동
  const { moveToPosition } = useCurrentPosition();

  //마커 상세 팝업
  const isMarkerModal = useBoolean();
  const [selectMarker, setSelectMarker] = useState<PhotoDataType | null>(null);
  const onMarkerDetail = (el: PhotoDataType) => {
    setSelectMarker(el);
    isMarkerModal.onTrue();
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <PhotoListContainerStyle>
              <UserProfileBox
                onCloseMyPhoto={onCloseModal}
                userData={selectUser}
              />
              <div className={`photo-list-box`}>
                <PhotoListStyle className="my-photo-list">
                  {photoList?.map((el: PhotoDataType) => (
                    <li key={el.uid}>
                      <img
                        src={el.image_url}
                        onClick={() => onMarkerDetail(el)}
                      />
                    </li>
                  ))}
                  {hasNextPage && !isFetchingNextPage && (
                    <li ref={moreRef} style={{ aspectRatio: "auto" }}>
                      <Loading size="sm" />
                    </li>
                  )}
                </PhotoListStyle>
                {!photoList && (
                  <div className="no-photo">저장한 사진이 없습니다.</div>
                )}
              </div>
            </PhotoListContainerStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
      {selectMarker && (
        <MarkerPopup
          onCloseMyPhoto={onCloseModal}
          moveToPosition={moveToPosition}
          data={selectMarker}
          isOpen={isMarkerModal.boolean}
          type={"list"}
          onClose={() => isMarkerModal.onFalse()}
        />
      )}
    </>
  );
};

export default UserPhotoList;
