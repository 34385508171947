import { useUserStore } from "@/store/useUserStore";
import React from "react";

type Props = {};

const index = (props: Props) => {
  const userInfo = useUserStore((state) => state.getUser());
  return <></>;
};

export default index;
