import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import ProfileBox from "../../profileBox";
import { PhotoListContainerStyle, PhotoListStyle } from "./style";
import {
  useGetBookmarkPhoto,
  useGetPhotoList,
} from "@/api/myphoto/useGetPhoto";
import { useEffect, useState } from "react";
import { BsBookmarkStar } from "react-icons/bs";
import { MdOutlinePhoto } from "react-icons/md";
import useBoolean from "@/hooks/useBoolean";
import MarkerPopup from "../MarkerDetail/MarkerPopup";
import Loading from "@/components/Loading";
import { useInView } from "react-intersection-observer";
import { PhotoDataType } from "@/api/myphoto/type";
import { useTabDrag } from "@/hooks/useTabDrag";
import { useCurrentPosition } from "@/hooks/useCurrentPosition";
import { UserInfo } from "@/store/useUserStore";
import ApplyUploaderModal from "../applyUploader";
import { useGetApplyUploader } from "@/api/auth/applyUploader";

interface MyphotoModalProps {
  userInfo: UserInfo;
  isMyPhotoOpen: boolean;
  onCloseMyPhoto: () => void;
  onDateSet: (date: string) => void;
}

function MyphotoModal({
  userInfo,
  isMyPhotoOpen,
  onCloseMyPhoto,
  onDateSet,
}: MyphotoModalProps) {
  //사진 리스트
  const [photoList, setPhotoList] = useState<PhotoDataType[]>([]);
  //북마크 리스트
  const [bookmarkPhotoList, setBookmarkPhotoList] = useState<PhotoDataType[]>(
    []
  );

  //내 사진 리스트 가져오기
  const {
    data: myPhotoData,
    isLoading: myPhotoLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPhotoList(userInfo.id, 20);
  const { ref: moreRef, inView: myPhotoInView } = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (myPhotoInView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [myPhotoInView]);

  //마커 상세 팝업
  const isMarkerModal = useBoolean();

  //사진 선택
  const [selectMarker, setSelectMarker] = useState<PhotoDataType | null>(null);
  const onMarkerDetail = (data: PhotoDataType) => {
    setSelectMarker(data);
    isMarkerModal.onTrue();
  };

  //해당 마커 위치로 이동
  const { moveToPosition } = useCurrentPosition(onDateSet);

  //즐겨찾기 가진 사진 리스트 가져오기
  const { ref: bookmarkMoreRef, inView: bookmarkInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: bookmarkPhotoData,
    fetchNextPage: bookmarkFetchNextPage,
    hasNextPage: bookmarkHasNextPage,
    isFetchingNextPage: bookmarkIsFetchingNextPage,
  } = useGetBookmarkPhoto(userInfo.book_mark, 20);
  useEffect(() => {
    if (bookmarkInView && bookmarkHasNextPage && !bookmarkIsFetchingNextPage) {
      bookmarkFetchNextPage();
    }
  }, [bookmarkInView]);

  //내사진/저장한사진 탭
  const [tab, setTab] = useState("myPhoto");

  const { touchState, translateX, handlers } = useTabDrag({
    initialTab: tab,
    onTabChange: setTab,
  });

  useEffect(() => {
    if (tab === "myPhoto") {
      setPhotoList(myPhotoData?.pages.flatMap((page) => page.data.list));
    } else {
      setBookmarkPhotoList(
        bookmarkPhotoData?.pages.flatMap((page) => page.data.list)
      );
    }
  }, [tab, myPhotoData, bookmarkPhotoData]);

  //업로더 신청
  const isApplyUploaderModal = useBoolean();
  const onApplyUploader = () => {
    isApplyUploaderModal.onTrue();
  };

  //업로더 신청 조회
  const { data: applyUploaderData, isLoading } = useGetApplyUploader(
    userInfo.id
  );

  const emptyBookmark = bookmarkPhotoList?.length === 0 || !bookmarkPhotoList;

  const uploaderState = applyUploaderData?.data?.list[0]?.state;
  return (
    <>
      <Modal
        isCentered
        isOpen={isMyPhotoOpen}
        onClose={onCloseMyPhoto}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <PhotoListContainerStyle>
              <ProfileBox onCloseMyPhoto={onCloseMyPhoto} type="list" />
              <ul className="photo-list-tab">
                <li
                  className={tab === "myPhoto" ? "active" : ""}
                  onClick={() => setTab("myPhoto")}
                >
                  <MdOutlinePhoto />내 사진
                </li>
                <li
                  className={tab === "savedPhoto" ? "active" : ""}
                  onClick={() => setTab("savedPhoto")}
                >
                  <BsBookmarkStar />
                  저장한 사진
                </li>
              </ul>
              <div className={`photo-list-box ${tab}`}>
                <div
                  className="move-box"
                  onTouchStart={handlers.touchTabMoveStart}
                  onTouchMove={handlers.touchTabMove}
                  onTouchEnd={handlers.touchTabEnd}
                  style={{
                    transform: touchState && `translateX(${translateX}%)`,
                    transition: "transform 0.3s ease",
                  }}
                >
                  {isLoading ||
                    (myPhotoLoading && (
                      <PhotoListStyle className="skeleton-list">
                        <li>
                          <Loading size="sm" />
                        </li>
                      </PhotoListStyle>
                    ))}
                  {
                    <>
                      <PhotoListStyle className="my-photo-list">
                        {photoList?.map((el: PhotoDataType) => (
                          <li key={el.uid}>
                            <img
                              src={el.image_url}
                              onClick={() => onMarkerDetail(el)}
                            />
                          </li>
                        ))}
                        {hasNextPage && !isFetchingNextPage && (
                          <li ref={moreRef} style={{ aspectRatio: "auto" }}>
                            <Loading size="sm" />
                          </li>
                        )}
                        {photoList?.length === 0 && (
                          <div className="no-photo">
                            {userInfo?.role === "uploader" ? (
                              <>저장한 사진이 없습니다.</>
                            ) : uploaderState === "ready" ? (
                              <Text mb={4}>업로더 승인 대기 중 입니다.</Text>
                            ) : (
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={"column"}
                              >
                                <Text mb={4}>
                                  업로더 계정이 아닙니다. 업로더 승인 이후
                                  등록이 가능합니다.
                                </Text>
                                <Button
                                  onClick={onApplyUploader}
                                  colorScheme={"purple"}
                                >
                                  업로더 신청하기
                                </Button>
                              </Flex>
                            )}
                          </div>
                        )}
                      </PhotoListStyle>
                    </>
                  }
                  {
                    <>
                      <PhotoListStyle className="bookmark-photo-list">
                        {bookmarkPhotoList?.map((el) => (
                          <li key={el.uid}>
                            <img
                              src={el.image_url}
                              onClick={() => onMarkerDetail(el)}
                            />
                          </li>
                        ))}
                        {bookmarkHasNextPage && !bookmarkIsFetchingNextPage && (
                          <li
                            ref={bookmarkMoreRef}
                            style={{ aspectRatio: "auto" }}
                          >
                            <Loading size="sm" />
                          </li>
                        )}
                        {emptyBookmark && (
                          <li className="no-photo">저장한 사진이 없습니다.</li>
                        )}
                      </PhotoListStyle>
                    </>
                  }
                </div>
              </div>
            </PhotoListContainerStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* 업로더 신청 팝업 */}
      {
        <ApplyUploaderModal
          isOpen={isApplyUploaderModal.boolean}
          onCloseModal={isApplyUploaderModal.onFalse}
        />
      }
      {/* 마커 상세 팝업 */}
      {selectMarker && (
        <MarkerPopup
          onCloseMyPhoto={onCloseMyPhoto}
          moveToPosition={moveToPosition}
          data={selectMarker}
          isOpen={isMarkerModal.boolean}
          onClose={() => isMarkerModal.onFalse()}
        />
      )}
    </>
  );
}

export default MyphotoModal;
