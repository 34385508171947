import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { format, subYears } from "date-fns";
import { getDownloadURL, ref as sRef, uploadBytes } from "firebase/storage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "../../../store/useUserStore";
import { api } from "../../../api";
import ImageUpload, { dataURLtoFile } from "../../ImageUpload";
import { storage } from "../../../firebase";
import { onUpdateThumbnail } from "../../../api/uploadImage";
import { ImageMetadata } from "../../../utils/imageMetadata";
import { CreateModalStyle } from "./style";
import { useRefetchStore } from "@/store/useRefetchStore";

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  setNewMarker: (marker: any) => void;
  newMarker: {
    id: string;
    latitude: number;
    longitude: number;
  };
  setSaveMode: (mode: boolean) => void;
  setRender: React.Dispatch<React.SetStateAction<number>>;
}

interface FormValues {
  title: string;
  date: string;
  link?: string;
  a?: string;
  image_url?: string;
  user_uid?: string;
  user_nick?: string;
  lat?: number;
  lng?: number;
  public: boolean;
}

const CreateModal = ({
  isOpen,
  onClose,
  newMarker,
  setSaveMode,
}: CreateModalProps) => {
  const locationListRefetch = useRefetchStore(
    (state) => state.locationListRefetch
  );
  const { getUser } = useUserStore();
  const userInfo = getUser();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const onCloseModal = () => {
    onClose();
  };

  const [clipImg, setClipImg] = useState([]); //이미지
  const [imgMetadata, setImgMetadata] = useState<ImageMetadata | null>(null); //이미지 메타데이터

  //메타데이터 저장
  const onImgMetadata = (data) => {
    setImgMetadata(data);
  };

  // 메타데이터 있을때 input에 추가
  useEffect(() => {
    if (imgMetadata?.dateTime) {
      const formattedDate = format(
        new Date(imgMetadata.dateTime),
        "yyyy-MM-dd'T'HH:mm"
      );
      setValue("date", formattedDate);
    } else {
      setValue("date", "");
    }
  }, [imgMetadata, setValue]);

  //과거 2년전까지 날짜 등록 가능
  let minDate = format(subYears(new Date(), 2), "yyy-MM-dd%HH:mm");
  let maxDate = format(new Date(), "yyy-MM-dd%HH:mm");
  minDate = minDate.replace("%", "T");
  maxDate = maxDate.replace("%", "T");

  //이미지 업로드
  const onUpdateImage = async (base64) => {
    let file = dataURLtoFile(base64, newMarker.id);
    const storageRef = sRef(storage, `images/${userInfo.uid}/${newMarker.id}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("이미지 업로드 중 오류가 발생했습니다:", error);
      throw error; // 오류를 호출자에게 전파합니다.
    }
  };
  const setImageLocation = async (location) => {
    const response = await api.put(`/photo.php`, location);
    return response.data;
  };

  const queryClient = useQueryClient();
  const addLocaMutation = useMutation({
    mutationFn: setImageLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["location"] });
      queryClient.invalidateQueries({ queryKey: ["photo"] });
      locationListRefetch && locationListRefetch();
    },
  });

  //신청
  const onSubmit = async (values) => {
    if (!clipImg[0]) {
      toast({
        description: "이미지를 첨부해 주세요",
        status: "info",
        duration: 1000,
        isClosable: false,
      });
      return;
    }

    const thumbnailUrl = await onUpdateThumbnail(
      clipImg[0],
      newMarker.id,
      userInfo.uid
    );
    const imageUrl = await onUpdateImage(clipImg[0]);
    values.a = "setLocation";
    values.link = values.link || "";
    values.image_url = imageUrl;
    values.thumbnail = thumbnailUrl;
    values.date = format(new Date(values.date), "yyyy-MM-dd HH:mm");
    values.user_uid = userInfo.uid;
    values.user_id = userInfo.id;
    values.lat = newMarker.latitude;
    values.lng = newMarker.longitude;
    values.public = values.public ? 1 : 0;
    addLocaMutation.mutate(values);
    onCloseModal();
    toast({
      description: "등록이 완료되었습니다.",
      status: "success",
      duration: 1000,
      isClosable: false,
    });
    setSaveMode(false);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size="xl">
      <ModalOverlay />
      <CreateModalStyle>
        <ModalHeader>
          사진 등록하기
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody pb={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <ImageUpload
                clipImg={clipImg}
                setClipImg={setClipImg}
                onImgMetadata={onImgMetadata}
              />
            </FormControl>
            <FormControl mt={4}>
              <Input fontSize="sm" {...register("title")} placeholder="제목" />
              <FormErrorMessage>
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4}>
              <Input
                fontSize="sm"
                {...register("date")}
                placeholder="Last name"
                type="datetime-local"
                min={minDate}
                max={maxDate}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <Input fontSize="sm" {...register("link")} placeholder="링크" />
              <FormErrorMessage>
                {errors.link && errors.link.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} display="flex" justifyContent={"flex-end"}>
              <Checkbox {...register("public")}>모든 사람에게 공개</Checkbox>
            </FormControl>
            <FormControl mt={4} display="none">
              <Input
                fontSize="sm"
                {...register("link")}
                placeholder="링크주소 (선택사항)"
              />
            </FormControl>
            <Flex mt={5}>
              <Button
                width="70%"
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isSubmitting}
              >
                등록
              </Button>
              <Button width="30%" onClick={onCloseModal}>
                취소
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </CreateModalStyle>
    </Modal>
  );
};

export default CreateModal;
