import { useApplyUploader } from "@/api/auth/applyUploader";
import { useUserStore } from "@/store/useUserStore";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};
interface FormData {
  introduction: string;
  sns: string;
  id: number;
  uid: string;
  nick: string;
}

const ApplyUploaderModal = ({ isOpen, onCloseModal }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const userInfo = useUserStore((state) => state.getUser());
  const toast = useToast();

  //업로더 신청
  const { mutate: applyUploader } = useApplyUploader();
  const onSubmit = (data: FormData) => {
    applyUploader(data, {
      onSuccess: () => {
        toast({
          description: "업로더 신청이 완료되었습니다.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        onCloseModal();
        reset();
      },
      onError: (error) => {
        toast({
          description: "업로더 신청에 실패했습니다.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      },
    });
  };
  const closeModal = () => {
    onCloseModal();
    reset();
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModal} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>업로더 신청하기</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input type="hidden" value={userInfo?.id} {...register("id")} />
            <Input type="hidden" value={userInfo?.uid} {...register("uid")} />

            <Input type="hidden" value={userInfo?.nick} {...register("nick")} />
            <FormControl mt={4}>
              <Textarea
                maxLength={200}
                placeholder="간단한 소개글을 입력해주세요"
                {...register("introduction", {
                  required: "소개글을 입력해주세요",
                })}
              />
              {errors.introduction && (
                <Text color="red.500" fontSize="sm">
                  {errors.introduction.message?.toString()}
                </Text>
              )}
            </FormControl>
            <FormControl mt={4}>
              <Input
                maxLength={40}
                type="text"
                placeholder="운영중인 sns 계정을 입력해주세요"
                {...register("sns", {
                  required: "sns 계정을 입력해주세요",
                })}
              />
              {errors.sns && (
                <Text color="red.500" fontSize="sm">
                  {errors.sns.message?.toString()}
                </Text>
              )}
            </FormControl>
            <Flex mt={6} mb={4} justifyContent="center">
              <Button width={"100%"} colorScheme="purple" type="submit">
                신청하기
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApplyUploaderModal;
