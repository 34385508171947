import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { GoKebabHorizontal } from "react-icons/go";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { api } from "../../api";
import { LuPencil } from "react-icons/lu";
import UpdateModal from "./UpdateModal";
import { deleteFileByUrl } from "@/api/removeImage";
import { useQueryClient } from "@tanstack/react-query";
import { ref, remove } from "firebase/database";
import { db } from "@/firebase";

export default function ModalMenu({ data, onCloseModal }) {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const onRemove = (data) => {
    const agree = confirm("삭제하시겠습니까?");
    if (!agree) {
      return;
    }
    api
      .post(`photo.php`, {
        a: "removeLocation",
        uid: data.uid,
      })
      .then((res) => {
        //스토리지에서 이미지 제거
        deleteFileByUrl(data.image_url);
        deleteFileByUrl(data.thumbnail);
        //firebase db 삭제
        const markerRef = ref(db, `marker/${data.uid}`);
        remove(markerRef);
        onCloseModal();
        toast({
          description: "삭제 되었습니다.",
          status: "success",
          duration: 1000,
          isClosable: false,
        });
        queryClient.invalidateQueries({ queryKey: ["locations"] });
        queryClient.invalidateQueries({ queryKey: ["photo"] });
      });
  }; //마커 삭제

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<GoKebabHorizontal />}
          variant="none"
        />
        <MenuList fontSize={12}>
          <MenuItem
            onClick={() => {
              onOpen();
            }}
            icon={<LuPencil fontSize={14} />}
          >
            수정
          </MenuItem>
          <MenuItem
            onClick={() => {
              onRemove(data);
            }}
            icon={<MdOutlineDeleteOutline fontSize={14} />}
          >
            삭제
          </MenuItem>
        </MenuList>
      </Menu>
      <UpdateModal data={data} isOpen={isOpen} onClose={onClose} />
    </>
  );
}
