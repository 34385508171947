import { colors } from "@/style/colors";
import styled from "styled-components";
import { mixColFlex, mixFlex } from "./mixIn";

const MapStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .btn-container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 400;
    padding-bottom: 2rem;
    gap: 0.5rem;
    .year-date {
      height: 35px;
      ${mixFlex("center", "center")};
      border-radius: 5px;
      background-color: #fff;
      width: 80px;
      font-size: 1rem;
      font-weight: bold;
    }
    .date-select-box {
      position: relative;
      height: 35px;
      .date-switch-box {
        ${mixFlex("center", "center")};
        position: absolute;
        left: 50%;
        top: -0.5rem;
        transform: translate(-50%, -100%);
        white-space: nowrap;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        span {
          cursor: pointer;
          flex: 1;
          ${mixFlex()};
          height: 28px;
          background-color: #fff;
          &.active {
            background-color: ${colors.PURPLE_500};
            color: #fff;
          }
        }
      }

      .react-datepicker-wrapper,
      .react-datepicker__input-container {
        height: 100%;
      }
      .react-datepicker-popper {
        width: 200px;
        .react-datepicker {
          border-radius: 0.5rem;
          border: 0;
        }
        .react-datepicker__month {
          ${mixColFlex("center", "center")};
          gap: 0.5rem;
        }
        .react-datepicker__header {
          background-color: ${colors.PURPLE_500};
          color: #fff;
        }
        .react-datepicker__navigation-icon {
          top: -3px;
        }
        .react-datepicker__navigation-icon::before {
          border-color: #fff !important;
        }
        .react-datepicker__month-wrapper {
          .react-datepicker__month-text--selected {
            background-color: ${colors.PURPLE_100};
            &:hover {
              background-color: ${colors.PURPLE_400};
              color: #fff;
            }
          }
        }
      }
      .custom-datepicker {
        cursor: pointer;
        height: 100%;
        width: 80px;
        font-size: 1rem;
        text-align: center;
        border-radius: 5px;
        caret-color: transparent;
        user-select: none;
        color: #555;
        font-weight: bold;
      }
    }
  }
  .save-pointer-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    top: 0;
    z-index: 400;
    width: 100%;
    height: 100%;
    color: #fff;
    padding-top: 4rem;
    .info-txt {
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;
      border-radius: 7px;
    }
  }
  .custom-div-icon {
    .my-custom-pin {
      width: 0.8rem;
      height: 0.8rem;
      background-color: ${colors.PURPLE_500};
      border-radius: 50%;
      ${mixFlex("center", "center")};
      div {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        background-color: ${colors.PURPLE_300};
        border-radius: 50%;
        animation: pulse 3s infinite;
        border: 2px solid ${colors.PURPLE_900};
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.3;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.2;
    }
    100% {
      transform: scale(1);
      opacity: 0.2;
    }
  }
`;

export { MapStyled };
