import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "..";

interface IApplyUploader {
  id: number;
  uid: string;
  nick: string;
  introduction: string;
  sns: string;
}

//업로더 신청
export const applyUploader = async (data: IApplyUploader) => {
  const response = await api.post("photo.php", {
    a: "postApplyUploader",
    ...data,
  });
  if (response.data.status === "success") {
    return response.data;
  } else {
    throw new Error(response.data.message || "업로더 신청에 실패했습니다.");
  }
};
export const useApplyUploader = () => {
  return useMutation({
    mutationFn: (data: IApplyUploader) => applyUploader(data),
  });
};

//업로더 신청 조회
export const getApplyUploader = async (id: number) => {
  const response = await api.post("photo.php", {
    a: "getApplyUploader",
    id: id,
  });
  return response;
};
export const useGetApplyUploader = (id: number) => {
  return useQuery({
    queryKey: ["getApplyUploader"],
    queryFn: () => getApplyUploader(id),
  });
};

//업로더 신청 목록
export const getApplyUploaderList = async () => {
  const response = await api.post("photo.php", {
    a: "getApplyUploaderList",
  });
  return response;
};
export const useGetApplyUploaderList = () => {
  return useQuery({
    queryKey: ["getApplyUploaderList"],
    queryFn: () => getApplyUploaderList(),
  });
};

//업로더 신청 승인
export const applyUploaderApprove = async (id: number) => {
  const response = await api.post("photo.php", {
    a: "applyUploaderApprove",
    id: id,
  });
  return response;
};
export const useApplyUploaderApprove = () => {
  return useMutation({
    mutationFn: (id: number) => applyUploaderApprove(id),
  });
};
