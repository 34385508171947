import { useEffect } from "react";
import { auth, db } from "../firebase";
import { onValue, ref } from "firebase/database";
import { useNavigate } from "react-router-dom";
import Map from "./Map";
import { useUserStore } from "../store/useUserStore";

function Main() {
  const navigate = useNavigate();
  const { setUser, clearUser } = useUserStore();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}`);
        onValue(userRef, (data) => {
          if (data.val()) {
            const userData = {
              ...user,
              ...data.val(),
            };
            setUser(userData);
          }
        });
      } else {
        clearUser();
        navigate("/login");
      }
    });
  }, []);

  return <Map />;
}

export default Main;
