import styled from "styled-components";
import { colors } from "../../../style/colors";
import { mixColFlex, mixFlex } from "../../../style/mixIn";

export const PopupContainerStyle = styled.div`
  .zoom {
    width: 400px;
  }
  .img-box {
    width: 100%;
    max-width: 400px;
    height: auto;
    max-height: calc(100vh - 20rem);
    overflow: hidden;
    ${mixFlex()};
  }
  .content-box {
    ${mixColFlex()}
    align-items: stretch;
    margin-top: 1rem;

    .reaction-box {
      ${mixFlex("flex-start")};
      gap: 0.75rem;
      margin-bottom: 0.5rem;
      svg {
        cursor: pointer;
      }
    }
    .popup-image {
      width: auto;
      height: 100%;
      object-fit: contain;
    }

    .info-list {
      padding: 5px 0;
    }

    .info-item {
      font-size: 1rem;
      display: flex;
      align-items: center;
    }

    .info-key {
      font-weight: bold;
    }

    .info-value {
      margin-left: 5px;
      font-weight: 600;
      &.link {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: ${colors.PURPLE_400};
      }
    }

    .top-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user {
        font-size: 1rem;
        font-weight: 600;
        ${mixFlex("flex-start")};
        gap: 0.25rem;
        cursor: pointer;
      }
    }
  }
`;
