import { Avatar, Button, useDisclosure } from "@chakra-ui/react";
import { MapStyled } from "../style/componentStyle";
import { format, subYears } from "date-fns";
import MyphotoModal from "./modal/myPhotoList";
import { useUserStore } from "../store/useUserStore";
import { useDateStore } from "../store/useDateStore";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { useEffect, useState } from "react";

function BottomMenu({
  handleCurrentLocation,
  onSaveMode,
  saveMode,
  setSaveMode,
}) {
  const {
    isOpen: isMyPhotoOpen,
    onOpen: onOpenMyPhoto,
    onClose: onCloseMyPhoto,
  } = useDisclosure();
  const { getUser } = useUserStore();
  const userInfo = getUser();
  const { date, setDate, setDateType: setDateTypeStore } = useDateStore();
  const queryClient = useQueryClient();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  //내 사진 리스트 모달 최초 열기 상태
  const [isMyPhotoOpenState, setIsMyPhotoOpenState] = useState(false);
  const onOpenMyPhotoState = () => {
    setIsMyPhotoOpenState(true);
    onOpenMyPhoto();
  };

  //과거 2년전까지 조회가능
  let minDate = format(subYears(new Date(), 2), "yyyy-MM");
  let maxDate = format(new Date(), "yyyy-MM");
  minDate = minDate.replace("%", "T");
  maxDate = maxDate.replace("%", "T");

  //리페칭
  const refetchLocations = async () => {
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
  };

  // 날짜 선택 핸들러 수정
  const onDateSet = (selectedDate: Date, type: string) => {
    if (type === "year") {
      const formattedDate = format(selectedDate, "yyyy");
      setDate(formattedDate);
    } else {
      const formattedDate = format(selectedDate, "yyyy-MM");
      setDate(formattedDate);
    }
    refetchLocations();
  };

  // 팝업에서 날짜 선택시
  const onPropsDateSet = (date: string) => {
    setDate(date);
    refetchLocations();
  };

  //전체/월간 선택 핸들러
  const [dateType, setDateType] = useState<string>("monthly");
  const onDateSwitch = (type: string) => {
    setDateType(type);
    setDateTypeStore(type);
    if (type === "year") {
      setDate(format(new Date(), "yyyy"));
    } else {
      setDate(format(new Date(), "yyyy-MM"));
    }
  };

  if (!userInfo) return null;
  return (
    <>
      <MapStyled>
        <div className="btn-container">
          <Avatar
            onClick={onOpenMyPhotoState}
            name="Dan Abrahmov"
            src={userInfo.profileImage || ""}
          />
          <div className="date-select-box">
            <div className="date-switch-box">
              <span
                className={dateType === "year" ? "active" : ""}
                onClick={() => onDateSwitch("year")}
              >
                연간
              </span>
              <span
                className={dateType === "monthly" ? "active" : ""}
                onClick={() => onDateSwitch("monthly")}
              >
                월간
              </span>
            </div>
            {dateType === "year" ? (
              <DatePicker
                selected={date ? new Date(date) : new Date()}
                onChange={(date: Date) => {
                  onDateSet(date, "year");
                  setIsCalendarOpen(false);
                }}
                dateFormat="yyyy"
                showYearPicker
                maxDate={new Date(maxDate)}
                locale={ko}
                readOnly={true} // 키보드 입력 방지
                className="custom-datepicker"
                onFocus={() => setSaveMode(false)}
                onKeyDown={(e) => e.preventDefault()}
                open={isCalendarOpen}
                onClickOutside={() => setIsCalendarOpen(false)}
                onInputClick={() => setIsCalendarOpen(!isCalendarOpen)}
              />
            ) : (
              <DatePicker
                selected={date ? new Date(date) : new Date()}
                onChange={(date: Date) => {
                  onDateSet(date, "monthly");
                  setIsCalendarOpen(false);
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                maxDate={new Date(maxDate)}
                locale={ko}
                readOnly={true} // 키보드 입력 방지
                className="custom-datepicker"
                onFocus={() => setSaveMode(false)}
                onKeyDown={(e) => e.preventDefault()}
                open={isCalendarOpen}
                onClickOutside={() => setIsCalendarOpen(false)}
                onInputClick={() => setIsCalendarOpen(!isCalendarOpen)}
              />
            )}
          </div>
          <Button
            boxShadow="lg"
            className="btn-move-currnet"
            fontSize={14}
            onClick={handleCurrentLocation}
          >
            현재위치
          </Button>
          {userInfo?.role === "uploader" && (
            <Button
              boxShadow="lg"
              colorScheme="purple"
              fontSize={14}
              onClick={onSaveMode}
            >
              {saveMode ? "위치탐색" : "위치저장"}
            </Button>
          )}
        </div>
      </MapStyled>
      {isMyPhotoOpenState && (
        <MyphotoModal
          onDateSet={onPropsDateSet}
          userInfo={userInfo}
          isMyPhotoOpen={isMyPhotoOpen}
          onCloseMyPhoto={onCloseMyPhoto}
        />
      )}
    </>
  );
}

export default BottomMenu;
