import { ProfileBoxStyle } from "./style";
import { Avatar, Flex, useDisclosure } from "@chakra-ui/react";
import ModifyProfileModal from "../modal/ModifyProfileModal";
import { IoIosClose } from "react-icons/io";
import { UserData } from "../modal/MarkerDetail/MarkerPopup";

const UserProfileBox = ({
  onCloseMyPhoto,
  userData,
}: {
  onCloseMyPhoto: () => void;
  userData: UserData;
}) => {
  const {
    isOpen: isProfileOpen,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();

  if (!userData) return null;
  return (
    <>
      <ProfileBoxStyle>
        <Flex align="center" gap={3}>
          <Avatar
            className="profile-image"
            src={userData.profile_image || ""}
          />
          <span className="nick">{userData.nick}</span>
        </Flex>
        <IoIosClose className="close-btn" onClick={onCloseMyPhoto} />
      </ProfileBoxStyle>
      <ModifyProfileModal
        userInfo={userData}
        isProfileOpen={isProfileOpen}
        onCloseProfile={onCloseProfile}
      />
    </>
  );
};

export default UserProfileBox;
